.container {
  padding: 16px 16px 52px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media screen and (min-width: 641px) {
  .container {
    padding: 8px 40px 32px;
    width: 600px;
  }
}

.image {
  margin-bottom: 24px;
  display: block;
  width: 80px;
  height: 80px;
}

@media screen and (min-width: 641px) {
  .image {
    margin: 12px 0 36px;
    width: 64px;
    height: 64px;
  }
}

.title {
  margin-bottom: 16px;
}

.content {
  margin-bottom: 24px;
}

.button-container {
  align-self: stretch;
}

@media screen and (min-width: 641px) {
  .button-container {
    align-self: initial;
  }
}
