.container {
  padding: 16px 16px 42px;
}

.image {
  margin-left: 8px;
  display: block;
  width: 24px;
  height: 24px;
}

.image-mobile {
  margin: 0 auto 24px;
  display: block;
  width: 80px;
  height: 84px;
}

.title {
  margin-bottom: 16px;
}

@media screen and (min-width: 641px) {
  .container {
    padding: 0 24px 24px;
    width: 400px;
  }

  .title {
    margin-bottom: 8px;
    display: flex;
    align-items: flex-end;
  }

  .content {
    margin-bottom: 32px;
  }
}
