.container {
  padding: 12px 16px;
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.form {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.title {
  margin-bottom: 8px;
}

.block {
  margin-bottom: 14px;
}

.submit {
  margin-top: auto;
  padding: 10px 0 8px;
}

.link {
  color: var(--accent-main-primary);
}

@media screen and (min-width: 641px) {
  .container {
    padding: 0 24px 24px;
    width: 448px;
  }

  .title {
    margin-bottom: 24px;
  }

  .submit {
    margin-top: 0;
    padding-top: 18px;
  }
}
